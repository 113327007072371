<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const twScreens = useTailwindScreens()
type TwScreens = typeof twScreens
type TwicPicsTransformations = { parameter: string, value: string }

export interface SourceImgConfig {
  width: number
  height: number
  /** Twicpics refit https://www.twicpics.com/docs/reference/transformations#refit */
  refitParameter?: string
  /** Twicpics string to add after refit */
  postRefitTransformation?: TwicPicsTransformations[]
}

export interface ResponsiveSourceImgConfig extends SourceImgConfig {
  breakpoint: keyof TwScreens
}

interface Props {
  path: string
  /** Order them from widest to tightest */
  sources?: ResponsiveSourceImgConfig[]
  defaultSource: SourceImgConfig
  withRefitTransformation?: boolean
  withCoverTransformation?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  withRefitTransformation: false,
  withCoverTransformation: false,
})

// helpers
const urlToSrc = (url: URL) => decodeURIComponent(url.href)
const numberToPx = (n: number) => `${n}px`

const { getSourceUrl } = useTwicpicsImage({ withRefitTransformation: props.withRefitTransformation, withCoverTransformation: props.withCoverTransformation })

// html picture
const getFallbackSrc = () => {
  const url = getSourceUrl({ path: props.path, config: props.defaultSource })
  return urlToSrc(url)
}
const fallbackSrc = getFallbackSrc()

const getSourceSrcSet = (config: SourceImgConfig) => {
  const DEVICE_PIXEL_RATIOS = [1, 2, 3]
  return DEVICE_PIXEL_RATIOS.map((dpr) => {
    const dprWidth = config.width * dpr
    const dprHeight = config.height * dpr
    const url = getSourceUrl({ path: props.path, config: { ...config, width: dprWidth, height: dprHeight } })
    return `${urlToSrc(url)} ${dprWidth}w`
  }).join(', ')
}

const getSourceMedia = ({ breakpoint }: ResponsiveSourceImgConfig) => `(min-width: ${twScreens[breakpoint]})`

const responsiveSources = props.sources?.map(source => ({
  ...source,
  media: getSourceMedia(source),
  srcset: getSourceSrcSet(source),
  sizes: numberToPx(source.width),
}))

const defaultResponsiveSource = {
  srcset: getSourceSrcSet(props.defaultSource),
  sizes: '100vw', // default to 100% viewport
}
</script>

<template>
  <picture>
    <!-- order matter -->
    <source
      v-for="source in responsiveSources"
      :key="`${props.path}-${source.breakpoint}`"
      :media="source.media"
      :srcset="source.srcset"
      :sizes="source.sizes"
    >

    <source
      :srcset="defaultResponsiveSource.srcset"
      :sizes="defaultResponsiveSource.sizes"
    >
    <!--
      fallback for unsupported devices
      class attribute apply to picture element
    -->
    <img
      :src="fallbackSrc"
      :width="defaultSource.width"
      :height="defaultSource.height"
      class="object-cover w-full h-full"
      v-bind="$attrs"
    >
  </picture>
</template>
